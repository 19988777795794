const path = {
    PUBLIC_PREFIX : '/',
    SALE_PREFIX : '/sale',
    MERGE_PREFIX : '/merge',
    MARKET_PREFIX : '/market/',
    PROFILE_PREFIX : 'profile',
    COLLECTION_PREFIX : 'kabanaclub',
    ACTIVITY_PREFIX : 'activity'
}

export default path;